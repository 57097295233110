import { Container, Row, Col, Button } from "react-bootstrap";
import singe from "../assets/images/Services/2.svg";

import service1 from "../assets/images/Services/singes/service-1.png"
import service2 from "../assets/images/Services/singes/service-2.png"
import service3 from "../assets/images/Services/singes/service-3.png"
import service4 from "../assets/images/Services/singes/service-4.png"


import ThemeProvider from 'react-bootstrap/ThemeProvider'

const CustomBreakpoints = {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
};




const Services = () => {
    return (
        <section id="services">
            <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
                <Container>
                    <Row className="d-flex justify-content-end align-items-center section-header pb-5">
                        <Col lg={8} md={8} xs={8} className="d-flex align-items-end flex-column">
                            <h2>NOS SERVICES</h2>
                            <h3>DES SERVICES SUR MESURE POUR TOUS VOS BESOINS</h3>
                        </Col>
                        <Col lg={1} md={2} xs={3}>
                            <img className="logo-singe-2" src={singe} alt="singe 2"/>
                        </Col>
                    </Row>
                    <Row className="card-container gx-3 justify-content-center">
                        <Col lg={3} md={6}  className="c-card c-card-desktop c-card-1">
                            <h3>PRIVATISATION</h3>
                            <p>POSSIBILITÉ DE PRIVATISER L’ENSEMBLE DU BAR.</p>
                        </Col>
                        <Col lg={3} md={6}  className="c-card c-card-desktop c-card-2 c-offset">
                            <h3>ANIMATION</h3>
                            <p>ORGANISATION DE SOIRÉES À THÈME, QUIZ, KARAOKÉ ET PLUS ENCORE.</p>
                        </Col>
                        <Col lg={3} md={6}  className="c-card c-card-desktop c-card-3">
                            <h3>RESTAURATION</h3>
                            <p>PLATS ET BOISSONS DISPONIBLES POUR RÉPONDRE À VOS BESOINS.</p>
                        </Col>
                        <Col lg={3} md={6}  className="c-card c-card-desktop c-card-4 c-offset">
                            <h3>OFFRES SPÉCIALES</h3>
                            <p>TARIFS PRÉFÉRENTIELS POUR LES GROUPES ET FORMULES ADAPTÉES.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center pt-5">
                            <a href="/#contact" className="l-btn-contact">
                                <div className="btn-contact">
                                    <span>CONTACT</span>
                                </div>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </ThemeProvider>
        </section>
    );
}


export default Services;
























