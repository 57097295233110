import { Container, Row, Col } from "react-bootstrap";
import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";
import TikTok from "../assets/images/tiktok.svg";
import logo from "../assets/images/logo-header.png";
import ThemeProvider from "react-bootstrap/ThemeProvider";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const Footer = () => {
  return (
    <footer className="footer">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <Container>
          <Row>
            <Col
              md={12}
              className="d-flex flex-column align-items-center text-center"
            >
              <h1 className="follow-us">NOUS SUIVRE</h1>
            </Col>
            <Col md={12} className="">
              <Row className="justify-content-center social-icons-row">
                <Col
                  md={2}
                  xs={4}
                  className="d-flex flex-column align-items-center text-center"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/..."
                  >
                    <img className="groupe-52-icon" alt="" src={Facebook} />
                  </a>
                </Col>
                <Col
                  md={2}
                  xs={4}
                  className="d-flex flex-column align-items-center text-center"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tiktok.com/..."
                  >
                    <img className="groupe-52-icon" alt="" src={TikTok} />
                  </a>
                </Col>
                <Col
                  md={2}
                  xs={4}
                  className="d-flex flex-column align-items-center text-center"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/..."
                  >
                    <img className="groupe-51-icon" alt="" src={Instagram} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col lg={12} md={12} xs={12}>
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center pb-5 info">
            <Col lg={3} md={3} xs={12} className="adresse">
              {/* <h3 className="contact-sstitre">ADRESSE</h3> */}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/ZJST66GjcNqYhGsTA"
              >
                <span className="place-de-lglise1">290 Quai la Pérouse,</span>
                <span className="place-de-lglise1">
                  {" "}
                  06700 Saint-Laurent-du-Var
                </span>
              </a>
            </Col>
            <Col lg={4} md={4} xs={12} className="support">
              {/* <h3 className="contact-sstitre">SUPPORT</h3> */}
              <div>
                <a href="tel:+33664755538">
                  <span className="contact-info">M / +33 6 64 75 55 38</span>
                </a>
                <a href="mailto:contact@welcometothemonkeys.com">
                  <span className="contact-info">
                    E / contact@welcometothemonkeys.com
                  </span>
                </a>
              </div>
            </Col>
            <Col md={3} lg={2} className="desktop"></Col>
            <Col md={3} lg={3} xs={12} className="d-flex flex-column text-end">
              <a href="/#">
                <img src={logo} alt="logo 3 singes" />
              </a>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col className="d-flex justify-content-center">
              <span class="pq-copyright">
                {/* Copyright&nbsp;
                  <script>
									  document.write(new Date().getFullYear())
								  </script> */}
                {/* 2024&nbsp;Monkeys - Tous droits réservés. -  */}
                Made with&nbsp;💜&nbsp;by&nbsp;
                <a target="_blank" href="https://maelcorp.com" rel="noreferrer">
                  MaelCorp.
                </a>
              </span>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </footer>
  );
};

export default Footer;
