import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/images/Bde/3.svg";
import img from "../assets/images/Bde/BDE.png";

// import chapeau from "../assets/images/Bde/right/casquette-de-graduation.png";
// import artifice from "../assets/images/Bde/right/feux-dartifice.png";
// import martini from "../assets/images/Bde/right/martini.png";
// import promotion from "../assets/images/Bde/right/promotion.png";

import chapeau from "../assets/images/Bde/right/casquette-de-graduation.svg";
import artifice from "../assets/images/Bde/right/feux-dartifice.svg";
import martini from "../assets/images/Bde/right/martini.svg";
import promotion from "../assets/images/Bde/right/promotion.svg";

import ThemeProvider from "react-bootstrap/ThemeProvider";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const Bde = () => {
  return (
    <section id="bde">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <Container>
          <Row className="section-header align-items-center pb-5">
            <Col lg={1} md={2} xs={3}>
              <img src={logo} className="logo-singe-3" alt="logo singe 3" />
            </Col>
            <Col lg={8} md={8} xs={8} className="d-flex flex-column">
              <h2>BDE</h2>
              <h3>LE SPOT DES ÉTUDIANTS</h3>
            </Col>
          </Row>
          <Row className="bde-container">
            <Col lg={6} md={12}>
              <img className="img-bde" src={img} alt="de fond" />
            </Col>
            <Col lg={4} md={12} className="info-bde">
              <Row className="flex-column">
                <Col>
                  <img className="bde-icon" src={artifice} alt="artifice" />
                </Col>
                <Col>
                  <h3>AMBIANCE ÉTUDIANTE</h3>
                </Col>
                <Col>
                  <p>UN CADRE DÉCONTRACTÉ IDÉAL POUR SE DÉTENDRE ENTRE AMIS.</p>
                </Col>
              </Row>
              <Row className="flex-column pt-5">
                <Col>
                  <img className="bde-icon" src={chapeau} alt="chapeau" />
                </Col>
                <Col>
                  <h3>RÉUNIONS ET PROJETS</h3>
                </Col>
                <Col>
                  <p>
                    ESPACES POUR TRAVAILLER EN GROUPE AVEC CONNEXION WI-FI
                    GRATUITE.
                  </p>
                </Col>
              </Row>
              <Row className="flex-column pt-5">
                <Col>
                  <img className="bde-icon" src={martini} alt="martini" />
                </Col>
                <Col>
                  <h3>ÉVÉNEMENTS ÉTUDIANTS</h3>
                </Col>
                <Col>
                  <p>DES SOIRÉES À THÈME POUR VOUS AMUSER ET FAIRE LA FÊTE.</p>
                </Col>
              </Row>
              <Row className="flex-column pt-5">
                <Col>
                  <img className="bde-icon" src={promotion} alt="promotion" />
                </Col>
                <Col>
                  <h3>PROMOTIONS ÉTUDIANTES</h3>
                </Col>
                <Col>
                  <p>
                    RÉDUCTIONS SPÉCIALES SUR LES BOISSONS ET LES PLATS POUR LES
                    ÉTUDIANTS.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </section>
  );
};

export default Bde;
