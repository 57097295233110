import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import '../css/hamburgers.css';
import logo from '../assets/images/logo-header.png';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [menuClass, setMenuClass] = useState('');

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            setMenuClass('show');
        } else {
            setMenuClass('hide');
        }
    }, [isOpen]);

    return (
        <>
            <Navbar expand="lg">
                <Navbar.Brand as={Link} to="/">
                    <div className='cercle'>
                        <img src={logo} alt='logo site'/>
                    </div>
                </Navbar.Brand>
                <button
                    className={`hamburger hamburger--collapse mobile ${isOpen ? 'is-active' : ''}`}
                    type="button"
                    onClick={toggleMenu}
                    aria-controls="basic-navbar-nav"
                    aria-expanded={isOpen}
                    aria-label="Toggle navigation"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <Navbar.Collapse id="basic-navbar-nav" className={`${menuClass}`}>
                    <Nav className="ml-auto">
                        <Nav.Link as={Link} to="/#about" onClick={toggleMenu}>À PROPOS</Nav.Link>
                        <Nav.Link as={Link} to="/#services" onClick={toggleMenu}>NOS SERVICES</Nav.Link>
                        <Nav.Link as={Link} to="/#bde" onClick={toggleMenu}>BDE & CE</Nav.Link>
                        <Nav.Link as={Link} to="/#contact" onClick={toggleMenu}>CONTACT</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Navigation;
