import { Col, Container, Row } from "react-bootstrap";
import singe from "../assets/images/About/1.svg";
import img1 from "../assets/images/About/Apropos.webp";
import ThemeProvider from "react-bootstrap/ThemeProvider";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const About = () => {
  return (
    <section id="about">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <div>
          <Container className="container-about about-section ">
            <Row>
              <Col lg={6} xs={12} className="pb-4">
                <Row className="d-flex align-items-center section-header pt-5 pb-5">
                  <Col lg={2} md={2} xs={4} className="offset-1">
                    <img src={singe} className="logo-singe-1" alt="singe 1" />
                  </Col>
                  <Col lg={8} md={8} xs={8}>
                    <h2 className="titre-about">À PROPOS</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={11} md={10} xs={9} className="offset-1">
                    <h3 className="sous-titre">NOTRE HISTOIRE</h3>
                    <p>
                      Un bar créé dans un bar. L’histoire folle d’un groupe
                      d’amis, qui décidèrent de se jeter dans le grand bain,
                      dans un projet à leur image pour vous faire vivre des
                      nuits de folies, au rythme des saisons de la côte d’Azur
                      avec une ambiance qui nous ressemble. Venez vivre nos
                      singeries !
                    </p>
                    <h3 className="sous-titre">NOTRE AMBIANCE</h3>
                    <p>
                      Dans un cadre idyllique, au port de Saint Laurent, entre
                      musiques enivrante et le cliquetis des bateaux, vous
                      pourrez profiter d’un apéro merveilleux comme d’une soirée
                      inarrêtable.
                    </p>
                    <h3 className="sous-titre">NOTRE ENGAGEMENT</h3>
                    <p>
                      L’entièreté de notre équipe donne, à chaque soirée passée,
                      son maximum pour vous faire vivre des moments merveilleux.
                      Nous tenons à donner une image de standing, de classe, de
                      légèreté parsemé d’un brin de folie pour créé un cadre
                      adéquat à VOS soirées.{" "}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} xs={12} className="container-right-fond">
                <div className="container-right">
                  <img src={img1} alt="ambiance 1" className="image1" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ThemeProvider>
    </section>
  );
};

export default About;
