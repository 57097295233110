import logo from '../assets/images/logo-header.png';
import Nav from "./Nav";
import { Container } from 'react-bootstrap';


const Header = () => {
    return (
        <>
            <header className="">
                <Container>
                    <Nav>
                    </Nav>
                </Container>
            </header>
        
        </>
    );
}

export default Header;