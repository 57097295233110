import React, { useState, useEffect } from 'react';
import '../css/style.css';
import "../css/responsive.css";

import Header from "../components/Header";
import Banner from '../components/Banner';
import About from '../components/About';
import Services from '../components/Services';
import Footer from "../components/Footer";
import Bde from "../components/Bde";
import Ce from "../components/Ce";
import Contact from "../components/Contact";
import Loader from "../components/Loader";
import anime from 'animejs';

const Main = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!loading) {
            anime({
                targets: '.main',
                opacity: [0, 1],
                easing: 'easeInOutQuad',
                duration: 1500
            });
        }
    }, [loading]);

    if (loading) {
        return (
            <Loader/>
        );
    }

    

    return (
        <div className="main">
            <Header />
            <Banner />
            <About />
            <Services />
            <Bde />
            <Ce />
            <Contact />
            <Footer />
        </div>
    );
};

export default Main;
